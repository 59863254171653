import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryJobPostsArgs } from '../../generated/types';

export const GET_JOB_POSTS = gql`
  query getJobPosts($where: JobPostsInputWhere!, $sort: InputSort!, $limit: Int) {
    jobPosts(where: $where, sort: $sort, limit: $limit) {
      uuid
      userUuid

      status

      isThroughAgency
      agencyName
      agentName
      agentEmail
      agentPhone
      referralFee

      company
      companyInfo
      companyWebsite
      companyLinkedIn
      companyAvatar
      companyLocation
      companyLocationCity
      companyLocationCountry
      companyLocationLatitude
      companyLocationLongitude
      companyLocationPostCode
      companyLocationStreet
      companyLocationUrl

      jobTitle
      jobSeniorityLevel
      jobDescription
      jobRequirement
      jobUrl

      contacts {
        uuid
        fullName
        position
        email
        phoneNumber
        linkedin
      }

      ir35
      duration
      rate
      employmentType
      remoteOption

      createdBy {
        uuid
        email
        nickname
        name
      }

      updatedBy {
        uuid
        email
        nickname
        name
      }

      createdAt
      updatedAt
      expiredAt

      permissions

      isRejected
      isDeleted
    }
  }
`;

export const useGetJobPosts = (options?: QueryHookOptions<Pick<Query, 'jobPosts'>, QueryJobPostsArgs>) =>
  useQuery<Pick<Query, 'jobPosts'>, QueryJobPostsArgs>(GET_JOB_POSTS, options);

export const useLazygetJobPosts = (options?: QueryHookOptions<Pick<Query, 'jobPosts'>, QueryJobPostsArgs>) =>
  useLazyQuery<Pick<Query, 'jobPosts'>, QueryJobPostsArgs>(GET_JOB_POSTS, options);
